import React, { useEffect, useState } from "react";
import axios from "axios";
import Menu from "./Menu";
import { ServiceNameDetails } from "../lib/ServicenameDetails";
import SelectSingleDate from "./SelectSingleDate";
import { fetchAirtimeLogsAPI } from "../api/api";

const AirtimeLogs = () => {
  const [airtimeLogs, setAirtimeLogs] = useState([]); // ✅ Fixed initial state

  const [values, setValues] = useState({
    msisdn: "",
    DATE: null,
    service: ServiceNameDetails[0].name,
  });

  const handleChange = (e) => {
    if (e?.target) {
      const { name, value } = e.target;
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        DATE: e,
      }));
    }
  };
  

  const fetchAirtimeLogs = async () => {
    try {
      const response = await axios.get(
        `${fetchAirtimeLogsAPI}?serviceName=${values.service}`
      );
      
      if (response.data && response.data.balances) {
        const sortedData = response.data.balances.sort(
          (a, b) => new Date(b.processDateTime) - new Date(a.processDateTime)
        );
        setAirtimeLogs(sortedData); // ✅ Set sorted API response
      } else {
        setAirtimeLogs([]); // ✅ Avoid errors by setting an empty array
      }
    } catch (error) {
      console.log(error);
      setAirtimeLogs([]); // ✅ Avoid crashes in case of errors
    }
  };
  
  

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchAirtimeLogs();
  };

  useEffect(() => {
    fetchAirtimeLogs(); // ✅ Fetch new data when service changes
  }, [values.service]);
  

  return (
    <>
      <div className="bg-black min-h-screen">
        <div className="sticky top-0 z-50">
          <Menu title={"EMT REPORTING PORTAL"} />
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col lg:flex-row justify-center items-center gap-4 mt-8"
        >
          <div className="lg:flex items-center">
            <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
              <label className="text-white text-xl">Select Service: </label>
              <select
                name="service"
                value={values.service}
                onChange={handleChange}
                className="border p-1 m-1 w-[180px] lg:m-4"
              >
                {ServiceNameDetails.map((service, i) => (
                  <option key={i} value={service.name}>
                    {service.name}
                  </option>
                ))}
              </select>
            </div>

            <SelectSingleDate handleDateChange={handleChange} />
            <button
              type="submit"
              className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80
           font-medium rounded-lg text-sm px-5 py-2.5 text-center lg:mr-2 ml-12 lg:ml-8 mb-2 lg:mt-0 mt-12 md:mt-18"
            >
              Submit
            </button>
          </div>
        </form>

        {/* Table Container */}
        <div className="flex justify-center mt-4">
          <div className="w-11/12 lg:w-3/4 overflow-x-auto shadow-lg">
            <table className="w-full text-sm text-left text-blue-100 dark:text-blue-100 mt-4">
              <thead className="text-md text-white uppercase bg-gradient-to-r from-cyan-400 to-blue-600">
                <tr>
                  <th scope="col" className="px-6 py-4 text-center">
                    Service
                  </th>
                  <th scope="col" className="px-6 py-4 text-center">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-4 text-center">
                    Airtime Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                {airtimeLogs.length > 0 ? (
                  airtimeLogs.map((item, i) => (
                    <tr
                      key={i}
                      className="bg-blue-100 text-black border-b border-blue-400"
                    >
                      <td className="px-4 sm:px-6 md:px-6 py-4 text-center">
                        {values.service}
                      </td>
                      <td className="px-4 sm:px-6 md:px-6 py-4 text-center">
                        {item.processDateTime.split("T")[0]}{" "}
                        {/* ✅ Extract date only */}
                      </td>
                      <td className="px-4 sm:px-6 md:px-6 py-4 text-center">
                        {item.balance}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      className="text-center text-white py-4 bg-gray-700 text-xl"
                    >
                      Loading...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AirtimeLogs;
